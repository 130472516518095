import ObjectCard from './ObjectCard'
import { ParsedObject } from '../../types'
import { Grid } from '@mui/material'

export interface ObjectsGridProps {
    objects: ParsedObject[]
}

const ObjectsGrid: React.FC<ObjectsGridProps> = ({ objects }: ObjectsGridProps) => (
    <Grid container spacing={2}>
        {objects.map((object, index) => (
            <Grid item key={`object-${index}`} xs={12} sm={6} md={4} lg={3}>
                <ObjectCard object={object} />
            </Grid>
        ))}
    </Grid>
)

export default ObjectsGrid
