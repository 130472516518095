import { FC } from 'react'
import { Grid } from '@mui/material'
import IndicatorCard, { IndicatorCardProps } from './IndicatorCard'

export interface IndicatorsGridProps {
    indicators: IndicatorCardProps[]
}

const IndicatorsGrid: FC<IndicatorsGridProps> = ({ indicators }) => (
    <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
        {indicators.map((indicator, index) => (
            <Grid item key={`indicator-${index}`} xs={12} sm={6} md={4}>
                <IndicatorCard {...indicator} />
            </Grid>
        ))}
    </Grid>
)

export default IndicatorsGrid
