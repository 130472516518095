/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { User } from '@/types/user'
import { signin, logout } from '../services/auth.service'
import { getMe } from '../services/user.service'
import useLocalStorage from '../hooks/useLocalStorage'
import MeSlice from '../store/slices/me.slice'
import { store } from '../store/configure.store'
import { camelCaseDeep } from '@/utils/camelCaseDeep'
import { gaSet } from '@/utils/ga.utils'
import { redirect } from 'react-router-dom'

interface UserContextProps {
    me?: User
    token?: string
    handleSignIn: (data: any) => Promise<void>
    handleSignOut: () => void
}

const UserContext = createContext<UserContextProps>({
    handleSignIn: async () => {},
    handleSignOut: async () => {},
})

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useLocalStorage<string | undefined>('token')
    const [me, setMe] = useState<User | undefined>(undefined)
    const fetchMe = async () => {
        const res = await getMe()
        if (res.me) {
            setMe(camelCaseDeep(res.me) as unknown as User)
            store.dispatch(MeSlice.actions.setMe({ ...res }))

            gaSet(res.me.UserId)
        }
    }
    useEffect(() => {
        if (token) {
            fetchMe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const handleSignIn = async (data: any) => {
        const res = await signin(data)
        if (res.jwt) {
            setToken(res.jwt)
            fetchMe()
            redirect('/')
        }
    }
    const handleSignOut = () => {
        logout()
    }

    return (
        <UserContext.Provider
            value={{
                me,
                token,
                handleSignIn,
                handleSignOut,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

const useAuth = () => useContext(UserContext)

export default useAuth
