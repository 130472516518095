import { FC } from 'react'
import { Skeleton, Grid } from '@mui/material'
import { INDICATOR_DATA_TYPES } from './types'

const LoadingSkeleton: FC = () => {
    return (
        <>
            <Grid container spacing={2}>
                {INDICATOR_DATA_TYPES.map((type) => (
                    <Grid item key={type} xs={12} sm={6} md={4}>
                        <Skeleton variant="rectangular" height={120} />
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={4} direction="column" sx={{ mt: 2 }}>
                {[0, 1].map((index) => (
                    <Grid item xs={12} key={index}>
                        <Skeleton variant="rectangular" height={400} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default LoadingSkeleton
