import { logout } from './auth.service';
import { post, get, del } from './utils.service'

export const getMe = async () => {
    const res = await get(`/users/me`)
    if (res.status === 401) {
        await logout();
    }
    return await res?.json()
}

export const updateMe = async (body) => {
    const res = await post(`/users/me`, { ...body })
    return await res?.json()
}

export const changePassword = async (body) => {
    const res = await post(`/users/password`, { ...body })
    return await res?.json()
}

export const help = async (body) => {
    const res = await post(`/users/help`, { ...body })
    return await res?.json()
}

export const updateOnboarding = async (body) => {
    const res = await post(`/users/onboarding`, { ...body })
    return await res?.json()
}

export const createUser = async (body, hash) => {
    const res = await post(`/users/create`, { ...body }, { SiteHash: hash })
    return await res?.json()
}

export const getAllUsers = async (siteId) => {
    const res = await get(`/users/all/${siteId}`)
    return await res?.json()
}

export const updateUser = async (body) => {
    const res = await post(`/users/update`, { ...body })
    return await res?.json()
}

export const getThreads = async () => {
    const res = await get(`/users/threads`)
    return await res?.json()
}

export const postThread = async (body) => {
    const res = await post(`/users/thread`, { ...body })
    return await res?.json()
}

export const deleteThread = async (id) => {
    const res = await del(`/users/thread/${id}`)
    return await res?.json()
}

export const postComplaint = async (body) => {
    const res = await post(`/users/complaint`, { ...body })
    return await res?.json()
}

export const getRating = async () => {
    const res = await get(`/users/rating`)
    return await res?.json()
}

export const postRating = async (body) => {
    const res = await post(`/users/rating`, { ...body })
    return await res?.json()
}

export const changePage = (body) => {
    return post(`/users/navigation`, { ...body })
}

export const buttonClick = (body) => {
    return post(`/users/buttonClick`, { ...body })
}
