import {
    Alert,
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Slider,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import useLocale from '@/utils/locale/locale.hook'
import SaveMenu from './save-menu.component'
import { useState } from 'react'

const marks = [
    {
        value: 10,
        label: '10°C',
    },
    {
        value: 15,
        label: '15°C',
    },
    {
        value: 20,
        label: '20°C',
    },
    {
        value: 25,
        label: '25°C',
    },
]

const SiteTelecommand = ({ addModification, style, saveModifications }) => {
    const locale = useLocale()
    const [open, setOpen] = useState(false)

    const handleSaveClick = () => {
        setOpen(true)
    }

    const handleConfirm = () => {
        setOpen(false)
        // Call the save function here or pass the event to SaveMenu
        saveModifications()
    }

    const handleCancel = () => {
        setOpen(false)
    }

    return (
        <>
            <Alert severity="warning" sx={{ mb: 2 }}>
                {locale?.['realTime']?.['siteWarning']}
            </Alert>
            <Grid container spacing={2} style={{ ...style }}>
                <Grid item xs={12} md={4}>
                    <FormControl
                        onChange={(e) => {
                            let value = e.target.value

                            addModification(0, value === 'on' ? 1 : 0, 'drive', null)
                        }}
                    >
                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                            <FormControlLabel value="on" control={<Radio />} label={locale?.['realTime']?.['allOn']} />
                            <FormControlLabel
                                value="off"
                                control={<Radio />}
                                label={locale?.['realTime']?.['allOff']}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={{ padding: { xs: '10px', sm: 0 } }}>
                        <Typography id="input-slider" gutterBottom>
                            Température demandée
                        </Typography>
                        <Slider
                            aria-label="Small steps"
                            valueLabelFormat={(val) => `${val}°C`}
                            step={1}
                            min={10}
                            max={25}
                            valueLabelDisplay="auto"
                            marks={marks}
                            onChange={(e) => {
                                addModification(0, e.target.value, 'tempOrder', null)
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleSaveClick}>
                    Enregistrer
                </Button>
            </Box>

            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Êtes-vous sûr de vouloir enregistrer les modifications ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SiteTelecommand
