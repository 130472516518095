import {
    RealtimeQueryData,
    ParsedObject,
    ParsedObjectStateData,
    RawConstraints,
    Constraints,
    ZonebjectBase,
    ZoneObject,
    Sensor,
    DataMeasurement,
    DataType,
    INDICATOR_DATA_TYPES,
    AverageValues,
    Mode,
} from '../types'
import mean from 'lodash/mean'

export const parseZoneObject = (zoneObject: ZoneObject, sitePilot = 1): Omit<ParsedObject, 'stateData'> => {
    return {
        ...zoneObject,
        pilot: Boolean(zoneObject.pilot * sitePilot),
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayToMap = <T extends Record<string, any>>(array: T[], key: keyof T): Record<T[typeof key], T> => {
    return array.reduce(
        (acc, item) => {
            acc[item[key]] = item
            return acc
        },
        {} as Record<T[typeof key], T>
    )
}

export const parseSensorData = (
    object: Pick<ZonebjectBase, 'objectId' | 'pmsId'>,
    allSensors: Sensor[],
    allDataMeasurements: Record<number, DataMeasurement>
): ParsedObjectStateData => {
    const { objectId, pmsId } = object
    const sensors = allSensors.filter((sensor) => sensor.objectId === objectId || (pmsId && sensor.pmsId === pmsId))
    return sensors.reduce((acc, sensor) => {
        const dataMeasurement = allDataMeasurements[sensor.sensorId]
        if (!dataMeasurement) {
            return acc
        }
        return {
            ...acc,
            [sensor.dataTypeId]: dataMeasurement.value,
        }
    }, {} as ParsedObjectStateData)
}

const parseRawConstraints = (rawContraints: RawConstraints | undefined, mode: Mode): Constraints | undefined => {
    if (!rawContraints) {
        return undefined
    }
    if (mode === Mode.HEAT) {
        return {
            min: rawContraints.constraintMinHeat,
            max: rawContraints.constraintMaxHeat,
        }
    }
    if (mode === Mode.COOL) {
        return {
            min: rawContraints.constraintMin,
            max: rawContraints.constraintMax,
        }
    }
    return undefined
}
export const parseRealtimeObjects = (
    realtimeData: RealtimeQueryData,
    siteConstraints: Record<number, RawConstraints> = {},
    sitePilot: number = 1
): ParsedObject[] => {
    const dataMeasurementsMap = arrayToMap(realtimeData.dataMeasurements, 'sensorId')
    return realtimeData.objects.map((zoneObject) => {
        const stateData = parseSensorData(zoneObject, realtimeData.sensors, dataMeasurementsMap)
        const mode = parseMode(stateData[DataType.MODE])
        const occupancyValue = stateData[DataType.OCCUPANCY]
        return {
            ...parseZoneObject(zoneObject, sitePilot),
            stateData,
            mode,
            state: Boolean(stateData[DataType.STATE]),
            temperature: stateData[DataType.TEMPERATURE],
            setpointTemperature: stateData[DataType.SETPOINT_TEMPERATURE],
            isError: Boolean(stateData[DataType.ERROR_MESSAGE]),
            constraints: parseRawConstraints(siteConstraints[zoneObject.objectId], mode),
            occupied: typeof occupancyValue === 'number' ? Boolean(occupancyValue) : undefined,
        }
    })
}

export const parseMode = (mode: number | undefined | null): Mode => {
    switch (mode) {
        case 1:
        case 3:
        case 11:
            return Mode.COOL
        case 2:
        case 12:
            return Mode.HEAT

        case 4:
            return Mode.FAN
        case 5:
            return Mode.AUTO
        default:
            return Mode.UNKNOWN
    }
}

export const getStateValuesAverages = (parsedObjects: ParsedObject[]): AverageValues =>
    INDICATOR_DATA_TYPES.map((dataType) => {
        const values = parsedObjects
            .map((object) => object.stateData[dataType])
            .filter((v) => {
                if (typeof v !== 'number') {
                    return false
                }
                if (dataType === DataType.STATE) {
                    return [0, 1].includes(v)
                }
                return !isNaN(v)
            })

        return { type: dataType, average: mean(values) }
    }, {} as AverageValues)
