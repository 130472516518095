import { FC } from 'react'
import { Box, Chip, ChipProps } from '@mui/material'

export interface BooleanFilterProps {
    values: boolean[]
    labels: string[] // for true, false
    onChange: (values: boolean[]) => void
    chipProps?: ChipProps
}

const BooleanFilter: FC<BooleanFilterProps> = ({ values, labels, onChange, chipProps }) => (
    <Box sx={{ display: 'flex', gap: 1 }}>
        {[true, false].map((value, index) => {
            const active = values.includes(value)
            const onToggle = () => {
                if (active) {
                    onChange(values.filter((v) => v !== value))
                } else {
                    onChange([...values, value])
                }
            }
            return (
                <Chip
                    key={index}
                    label={labels[value ? 0 : 1]}
                    color={active ? 'primary' : 'default'}
                    onClick={onToggle}
                    {...chipProps}
                />
            )
        })}
    </Box>
)

export default BooleanFilter
