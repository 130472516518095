import { FC } from 'react'
import { RealtimeContextValue } from './useRealtime'
import { Paper, Typography, Grid, Box } from '@mui/material'
import GroupItem from './components/group-item.component'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SiteRoutes } from '../siteRoutes'

const GroupsList: FC<RealtimeContextValue> = ({ groups, rawSensors }) => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    return (
        <Paper sx={{ p: 2, my: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                {t('tabs.groups')} ({groups.length})
            </Typography>
            <Grid container spacing={2}>
                {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    groups.map((group: any) => (
                        <Grid item key={group.name} xs={12} sm={6} md={4} lg={3}>
                            <GroupItem key={group.name} item={group} sensors={rawSensors} />
                        </Grid>
                    ))
                }
            </Grid>
            {groups.length === 0 && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" gutterBottom>
                        {t('noGroup.title')}
                    </Typography>
                    <Typography>
                        {t('noGroup.cta')}&nbsp;
                        <Link to={`../${SiteRoutes.CONSTRAINTS}`} relative="path">
                            {t('noGroup.link')}
                        </Link>{' '}
                        {t('noGroup.ctaEnd')}{' '}
                    </Typography>
                </Box>
            )}
        </Paper>
    )
}

export default GroupsList
