import { ParsedObject } from '../types'
import { useState } from 'react'
import { fuzzyMatch } from '@/utils/fuzzyMatch'
import useSearch from '@/hooks/useSearch'

export type FilterableBoolean = keyof Pick<ParsedObject, 'pilot' | 'state'>

type BooleanFilterValues = Record<FilterableBoolean, boolean[]>

const filterObjects = (parsedObjects: ParsedObject[], booleanFilterValues: BooleanFilterValues) => {
    return parsedObjects.filter((parsedObject) => {
        return Object.entries(booleanFilterValues).every(([key, values]) => {
            if (values.length === 0) {
                return true
            }
            return values.includes(parsedObject[key as FilterableBoolean])
        })
    })
}

export const useFilters = (parsedObjects: ParsedObject[]) => {
    const booleanFiltersDefaultValues: BooleanFilterValues = {
        pilot: [],
        state: [],
    }
    const [booleanFilterValues, setBooleanFilterValues] = useState<BooleanFilterValues>(booleanFiltersDefaultValues)
    const { search, setSearch, debouncedSearch, handleClearSearch } = useSearch()

    const resetFilters = () => {
        setBooleanFilterValues(booleanFiltersDefaultValues)
        handleClearSearch()
    }
    const filtersActive = Object.values(booleanFilterValues).some((values) => values.length > 0) || search.length > 0
    const booleanFilteredObjects = filterObjects(parsedObjects, booleanFilterValues)
    const searchQueryMatches = fuzzyMatch(booleanFilteredObjects, 'name', debouncedSearch)

    return {
        booleanFilterValues,
        setBooleanFilterValues,
        filteredObjects: searchQueryMatches.map(({ item }) => item),
        resetFilters,
        filtersActive,
        search,
        setSearch,
    }
}
