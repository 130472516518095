export interface DataMeasurement {
    dataMeasurementId: number
    dateTime: string
    sensorId: number
    value: number
}

export interface ObjectPms {
    objectId: number
    objectPmsId: number
    pmsId: number
}
export enum ObjectTypeId {
    SITE = 1,
    BUILDING = 2,
    FLOOR = 3,
    THERMALZONE = 4,
    SUBZONE = 5,
    OTHER = 8,
    ROOM = 9,
    SPACE = 10,
}

export enum SpaceType {
    HOTEL_ROOM = 'Hotel_Room',
    COMMON_AREA = 'Common_Area',
    RESTAURANT = 'Restaurant',
    TECHNICAL_ZONE = 'Technical_Zone',
    OFFICE = 'Office',
    MEETING_ROOM = 'Meeting_Room',
    OPEN_SPACE = 'Open_Space',
    OTHER = 'Other',
}
export interface ZonebjectBase {
    objectId: number
    pmsId?: number
    objectHash: number | null
    objectTypeId: ObjectTypeId
    spaceType: SpaceType | null
    name: string
    pilot: number
}

export interface RawConstraints {
    constraintMin: number
    constraintMax: number
    constraintMinHeat: number
    constraintMaxHeat: number
}
export interface Constraints {
    min: number
    max: number
}
export interface ZoneObject extends ZonebjectBase {
    mode: Mode
    state: boolean
    occupied?: boolean
    setpointTemperature?: number | null
    temperature?: number | null
    constraints?: Constraints
    isError?: boolean
}

export enum DataType {
    TEMPERATURE = 184,
    MODE = 225,
    SETPOINT_TEMPERATURE = 226,
    STATE = 228,
    FAN_SPEED = 255,
    ERROR_MESSAGE = 326,
    OCCUPANCY = 327,
}

export const DataTypeUnits: Record<DataType, string | null> = {
    [DataType.TEMPERATURE]: '°C',
    [DataType.SETPOINT_TEMPERATURE]: '°C',
    [DataType.FAN_SPEED]: null,
    [DataType.STATE]: null,
    [DataType.MODE]: null,
    [DataType.ERROR_MESSAGE]: null,
    [DataType.OCCUPANCY]: null,
}

export interface Sensor {
    sensorId: number
    name: string
    dataTypeId: DataType
    equiplentId: number
    objectId: number | null
    pmsId?: number
}

export interface RealtimeQueryData {
    dataMeasurements: DataMeasurement[]
    objectPms: ObjectPms[]
    objects: ZoneObject[]
    sensors: Sensor[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rawSensors: any[]
}

export type ParsedObjectStateData = Partial<Record<DataType, number | null>>

export interface ParsedObject extends Omit<ZoneObject, 'pilot'> {
    objectId: number
    objectHash: number | null
    name: string
    pilot: boolean
    stateData: ParsedObjectStateData
}

export const INDICATOR_DATA_TYPES = [DataType.STATE, DataType.TEMPERATURE, DataType.SETPOINT_TEMPERATURE] as const
export type IndicatorDataType = (typeof INDICATOR_DATA_TYPES)[number]
export type AverageValues = { type: IndicatorDataType; average: number }[]

export enum Mode {
    COOL = 'COOL',
    HEAT = 'HEAT',
    FAN = 'FAN',
    AUTO = 'AUTO',
    UNKNOWN = 'UNKNOWN',
}

export interface NotificationData extends DataMeasurement {
    sensor: Sensor
    object: ZonebjectBase
}
