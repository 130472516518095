type Sortable = {
    name: string
}

/**
 * Comparison method to use in Array.sort() to sort objects by name
 * @param a
 * @param b
 * @returns
 */
export const compareObjectsName = (a: Sortable, b: Sortable): number => {
    const aDigits = a.name.match(/\d+/g)
    const bDigits = b.name.match(/\d+/g)
    if (aDigits && bDigits) {
        const aName = a.name.replace(/\d+/g, '')
        const bName = b.name.replace(/\d+/g, '')
        if (aName === bName) {
            return Number(aDigits[0]) - Number(bDigits[0])
        }
        return aName.localeCompare(bName)
    }
    return a.name.localeCompare(b.name)
}
