import {
    Alert,
    Box,
    Grid,
    Chip,
    Snackbar,
    TextField,
    Typography,
    FormLabel,
    Skeleton,
    useMediaQuery,
} from '@mui/material'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import OccupancyEmpty from '@/assets/img/realtime/occupancy_empty.png'
import OccupancyFull from '@/assets/img/realtime/occupancy_full.png'
import { electricBlue, textBlueLight } from '@/components/Basic/colors'
import TemperatureInput from '@/components/form/temperature.component'
import AgridToggle from '@/components/Global/toggle.component'
import { getDataPoint, getTimeSeries } from '@/services/sensors.service'
import { hexToRGB } from '@/utils/global.utils'
import useLocale from '@/utils/locale/locale.hook'
import FanComponent from './fan.component'
import SaveMenu from './save-menu.component'
import ThermostatIcon from '@mui/icons-material/Thermostat'

const Telecommand = ({ sensors, addModification, objectId, saveModifications, items, hash }) => {
    const [loading, setLoading] = useState(true)
    const [tempOrder, setTempOrder] = useState(null)
    const [temperature, setTemperature] = useState(null)
    const [mode, setMode] = useState(null)
    const [drive, setDrive] = useState(null)
    const [fanSpeed, setFanSpeed] = useState(null)
    const [timeSeries, setTimeSeries] = useState(null)
    const [humidity, setHumidity] = useState(null)
    const [occupancy, setOccupancy] = useState(null)
    const [showAlert, setShowAlert] = useState()
    const locale = useLocale()
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(30)
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        if (sensors?.length > 0) {
            let res = await getDataPoint({
                hash,
                sensors: sensors.map((el) => el.SensorId),
                objectId,
            })
            for (let i = 0; i < sensors?.length; i++) {
                let id = sensors[i]?.SensorId
                let measurement = res?.measurement?.find((el) => el.sensorId == id)
                sensors[i] = { ...sensors[i], ...measurement }
            }

            let item = sensors?.find((el) => el?.DataTypeId === 226)
            if (item?.data?.length > 0) setTempOrder(item)
            item = sensors?.find((el) => el?.DataTypeId === 225)
            if (item?.data?.length > 0) setMode(item)
            item = sensors?.find((el) => el?.DataTypeId === 228)
            if (item?.data?.length > 0) setDrive(item)
            item = sensors?.find((el) => el?.DataTypeId === 255)
            if (item?.data?.length > 0) {
                item.options = item?.options?.sort((a, b) => a.IdGlobal - b.IdGlobal)
                setFanSpeed(item)
            }
            item = sensors?.find((el) => el?.DataTypeId === 313)
            if (item?.data?.length > 0) {
                setOccupancy(item)
            }

            item = sensors?.find((el) => el?.DataTypeId === 184)
            if (item && item?.data?.[0]?.Value) {
                setTemperature(`${item?.data?.[0]?.Value} ${item?.Unit}`)

                let yesterday = new Date()
                yesterday = yesterday.setDate(yesterday.getDate() - 1)
                const res = await getTimeSeries({
                    sensors: [item?.SensorId],
                    hash,
                    StartDateTime: yesterday,
                })
                if (res?.measurements?.length > 0) {
                    setTimeSeries(res?.measurements)
                }
            }
            item = sensors?.find((el) => el?.DataTypeId === 175)
            if (item?.data?.length > 0) {
                setHumidity(item)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (timeSeries?.length > 0) {
            let minValue = Math.min(...timeSeries.map((el) => parseFloat(el.Value)))
            let maxValue = Math.max(...timeSeries.map((el) => parseFloat(el.Value)))
            let diff = maxValue - minValue
            let margin = 0.01
            let minWithMargin = minValue - Math.min(minValue * margin, diff / 2)
            let maxWithMargin = maxValue + Math.min(maxValue * margin, diff / 2)
            setMin(minWithMargin)
            setMax(maxWithMargin)
        }
    }, [timeSeries])

    const value = drive?.data?.[0]?.Value === 1 ? 1 : 0
    const isOn = drive?.options?.find((el) => el?.IdGlobal === value)?.Name?.toLowerCase() === 'on'
    if (loading) {
        return (
            <>
                <Typography variant="h2" sx={{ mb: 4 }}>
                    <Skeleton width="60%" />
                </Typography>
                <Skeleton variant="rectangular" height={500} />
            </>
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            {showAlert && (
                <Snackbar
                    style={{ zIndex: 1000 }}
                    open={showAlert}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    autoHideDuration={6000}
                    onClose={() => setShowAlert(null)}
                >
                    <Alert severity={showAlert}>
                        {showAlert === 'warning' ? locale?.['realTime']?.['error'] : locale?.['realTime']?.['success2']}
                    </Alert>
                </Snackbar>
            )}

            <Box>
                <Grid container alignItems="center" sx={{ mb: 4 }} spacing={2} justifyContent="space-between">
                    <Grid
                        item
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={3}
                        xs={12}
                        md={10}
                        lg={8}
                    >
                        <Grid item xs={4} md={2} lg={1}>
                            {drive && (
                                <AgridToggle
                                    checked={isOn}
                                    onChange={(val) => {
                                       
                                        setDrive({
                                            ...drive,
                                            data: [
                                                {
                                                    ...drive?.data[0],
                                                    Value: val,
                                                },
                                            ],
                                        })
                                        addModification(drive?.actuator?.ActuatorId, val, '', drive?.sensors)
                                    }}
                                    name="status"
                                    styles={{ toggle: { margin: 0 }, label: { color: textBlueLight } }}
                                    disabled={!drive?.actuator}
                                />
                            )}
                        </Grid>
                        {isMobile && (
                            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <SaveMenu title="Enregistrer" saveModifications={saveModifications} items={items} />
                            </Grid>
                        )}

                        {mode?.data?.[0]?.Value !== undefined && mode?.data?.[0]?.Value !== null ? (
                            <Grid item xl={1} md={2} sm={3} xs={6}>
                                <TextField
                                    InputLabelProps={{
                                        style: { color: textBlueLight },
                                    }}
                                    id="outlined-basic"
                                    label={locale?.['realTime']?.['mode']}
                                    variant="standard"
                                    value={
                                        mode?.options?.find((el) => el?.IdGlobal === mode?.data?.[0]?.Value)?.Name || ''
                                    }
                                    disabled
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        ) : null}
                        {tempOrder?.data?.[0]?.Value ? (
                            <Grid item>
                                <TemperatureInput
                                    min={tempOrder?.actuator?.Min}
                                    max={tempOrder?.actuator?.Max}
                                    onChange={(value) => {
                                        if (
                                            mode?.options?.find((el) => el?.IdGlobal === mode?.data?.[0]?.Value)
                                                ?.Name === 'FAN'
                                        )
                                            return
                                        setTempOrder({
                                            ...tempOrder,
                                            data: [{ ...tempOrder?.data[0], Value: value }],
                                        })
                                        addModification(tempOrder?.actuator?.ActuatorId, value, '', tempOrder.sensors)
                                    }}
                                    value={tempOrder?.data?.[0]?.Value || ''}
                                    unit={tempOrder?.Unit}
                                    label={locale?.['realTime']?.['order']}
                                    disabled={
                                        mode?.options?.find((el) => el?.IdGlobal == mode?.data?.[0]?.Value)?.Name ==
                                            'FAN' || !tempOrder?.actuator
                                    }
                                />
                                {tempOrder?.actuator ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                        }}
                                    ></div>
                                ) : null}
                            </Grid>
                        ) : null}
                        {fanSpeed && fanSpeed?.data?.[0]?.Value !== undefined && fanSpeed?.data?.[0]?.Value !== null ? (
                            <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <FormLabel style={{ fontSize: '12px', color: textBlueLight }}>
                                    {locale?.['realTime']?.['fanSpeed']}
                                </FormLabel>
                                <FanComponent
                                    fanSpeed={fanSpeed}
                                    length={fanSpeed?.options?.length}
                                    options={fanSpeed?.options}
                                    selected={
                                        parseInt(
                                            fanSpeed?.options
                                                ?.map((el) => el?.IdGlobal)
                                                ?.indexOf(fanSpeed?.data?.[0]?.Value)
                                        ) + 1 || 0
                                    }
                                    onClick={(newValue) => {
                                        let val = fanSpeed?.options?.[newValue]?.IdGlobal
                                        setFanSpeed({
                                            ...fanSpeed,
                                            data: [
                                                {
                                                    ...fanSpeed?.data[0],
                                                    Value: val,
                                                },
                                            ],
                                        })
                                        addModification(fanSpeed?.actuator?.ActuatorId, val, '', sensors)
                                    }}
                                />
                            </Grid>
                        ) : null}

                        {occupancy?.data?.[0]?.Value !== undefined && occupancy?.data?.[0]?.Value !== null ? (
                            <Grid item xs={6} md={2}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.38)' }}>
                                        {locale?.['realTime']?.['occupation']}
                                    </Typography>
                                    <img
                                        src={occupancy?.data?.[0]?.Value ? OccupancyFull : OccupancyEmpty}
                                        style={{ width: 20 }}
                                    />
                                </div>
                            </Grid>
                        ) : null}
                        {humidity?.data?.[0]?.Value !== undefined && humidity?.data?.[0]?.Value !== null && (
                            <Grid item xs={6} md={2}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.38)' }}>
                                        Humidité
                                    </Typography>
                                    {humidity?.data?.[0]?.Value}%
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    {!isMobile && (
                        <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <SaveMenu title="Enregistrer" saveModifications={saveModifications} items={items} />
                        </Grid>
                    )}
                </Grid>

                {temperature && (
                    <Box sx={{ mb: 2 }}>
                        <Chip
                            label={
                                <span>
                                    Température ambiante&nbsp;&nbsp;<b>{temperature}</b>
                                </span>
                            }
                            icon={<ThermostatIcon fontSize="small" />}
                        />
                    </Box>
                )}

                {timeSeries?.length > 0 ? (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'area',

                                zoomBySingleTouch: true,
                                zoomType: 'x',
                            },
                            legend: { enabled: false },
                            title: {
                                text: '',
                                enabled: false,
                            },
                            xAxis: {
                                type: 'datetime',
                                labels: {
                                    format: '{value:%d/%m, %Hh%M}',
                                    align: 'center',
                                },
                            },
                            yAxis: {
                                title: {
                                    text: 'Temperature (°C)',
                                },
                                min,
                                max,
                                tooltip: {
                                    formatter: function () {
                                        return this.value + '°C'
                                    },
                                },
                            },
                            plotOptions: {
                                areaspline: {
                                    fillOpacity: 0.4,
                                },
                            },

                            tooltip: {
                                formatter: function () {
                                    return `<b>${this.y}°C</b>`
                                },
                            },

                            series: [
                                {
                                    name: 'Temp. mesurée',
                                    data: timeSeries?.map((el) => {
                                        let date = moment(el?.DateTime).toDate()
                                        date = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                                        return {
                                            y: parseInt(parseFloat(el?.Value) * 10) / 10,
                                            x: date.getTime(), //el.DateTime,
                                        }
                                    }),
                                    hash: 4,
                                    visible: true,
                                    marker: {
                                        enabled: false,
                                    },
                                    type: 'area',
                                    spacingLeft: 10,

                                    color: {
                                        linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
                                        stops: [
                                            [0, electricBlue],
                                            [1, hexToRGB(electricBlue, 0.5)],
                                        ],
                                    },
                                },
                            ],
                        }}
                    />
                ) : loading ? null : (
                    <Box>
                        <Alert severity="warning">{locale?.['realTime']?.['alert']}</Alert>
                    </Box>
                )}
            </Box>
        </div>
    )
}
export default Telecommand
