import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Box, Tooltip } from '@mui/material'
import { electricBlue } from '@/components/Basic/colors'
import { useTranslation } from 'react-i18next'

interface OccupancyIndicatorProps {
    occupied?: boolean
}

const OccupancyIndicator: React.FC<OccupancyIndicatorProps> = ({ occupied }) => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime.objectCard.occupancy' })
    const tooltipTitle = typeof occupied === 'undefined' ? t('unknown') : occupied ? t('occupied') : t('free')
    return (
        <Box
            sx={{
                backgroundColor: occupied ? electricBlue : 'grey.200',
                p: 0.75,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Tooltip title={tooltipTitle}>
                <PeopleAltIcon color={occupied ? 'primary' : 'disabled'} />
            </Tooltip>
        </Box>
    )
}

export default OccupancyIndicator
