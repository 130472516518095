import { FC } from 'react'
import { Card, Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IndicatorDataType } from '../../types'

export interface IndicatorCardProps {
    title: IndicatorDataType
    value: React.ReactNode
    iconSrc: string
}

const IndicatorCard: FC<IndicatorCardProps> = ({ title, value, iconSrc }) => {
    const { t } = useTranslation('site')
    const { spacing } = useTheme()
    return (
        <Card sx={{ py: 2, px: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: -2 }}>
                <img
                    src={iconSrc}
                    alt={t(`realtime.dataTypeAverageIndicators.${title}`)}
                    style={{ width: spacing(4) }}
                />
            </Box>
            <Typography variant="h3" component="span">
                {value}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
                {t(`realtime.dataTypeAverageIndicators.${title}`)}
            </Typography>
        </Card>
    )
}

export default IndicatorCard
