import { ActionStatus } from '../useRealtime'
import { Snackbar, Alert, AlertProps, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ActionStatusSnackbarProps {
    status: ActionStatus | null
    onClose: () => void
}

const severities: Record<ActionStatus, AlertProps['severity']> = {
    [ActionStatus.PENDING]: 'info',
    [ActionStatus.SUCCESS]: 'success',
    [ActionStatus.ERROR]: 'warning',
}

const ActionStatusSnackbar: React.FC<ActionStatusSnackbarProps> = ({ status, onClose }) => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime.actionStatusSnackbar' })

    if (!status) {
        return null
    }

    const isPending = status === ActionStatus.PENDING

    return (
        <Snackbar
            open={!!status}
            onClose={onClose}
            autoHideDuration={isPending ? null : 5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert
                severity={severities[status]}
                onClose={onClose}
                icon={isPending ? <CircularProgress size={20} /> : undefined}
            >
                {t(status)}
            </Alert>
        </Snackbar>
    )
}

export default ActionStatusSnackbar
