import EditIcon from '@mui/icons-material/Edit'
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import moment from 'moment'
import 'moment/locale/fr'
import React, { useEffect, useState } from 'react'
import PilotOnSvg from '@/assets/img/realtime/pilot.svg'
import PilotOffSvg from '@/assets/img/realtime/pilot_off.svg'
import AgridToggle from '@/components/Global/toggle.component'
// import { AgridLite } from './agridlite.component'
import { CalendarLinearComponent } from './calendar-linear.component'
import TemperatureInput from './temp.component'
import { useSite } from '../../useSite'
import { NotFoundError } from '@/utils/error.utils'

const ConstraintModal = ({ save, constraints, setModified, SaveMenu, objectNewName, setObjectNewName }) => {
    const { object } = useSite()
    const [constraintMin, setconstraintMin] = useState(19)
    const [constraintMax, setconstraintMax] = useState(26)
    const [constraintMinHeat, setconstraintMinHeat] = useState(19)
    const [constraintMaxHeat, setconstraintMaxHeat] = useState(26)
    const [noAgenda, setNoAgenda] = useState(false)
    const [agendaType, setAgendaType] = useState('classic')
    const [events, setEvents] = useState([])
    const [pilotEvents, setPilotEvents] = useState([]) // agenda for pilot
    const [snack, setSnack] = useState(false)
    const [snackData, setSnackData] = useState({})
    const [pilot, setPilot] = useState(0)
    const [alert, setAlert] = useState(null)
    const [loading, setLoading] = useState(false)

    if (object === null) {
        throw new NotFoundError('Object is missing')
    }
    useEffect(() => {
        save({
            events,
            constraintMin,
            constraintMax,
            applyToAll: false,
            constraintMinHeat,
            constraintMaxHeat,
            pilot: pilot ? 1 : 0,
            noAgenda,
            pilotEvents,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constraintMin, constraintMax, events, constraintMinHeat, constraintMaxHeat, pilot, noAgenda, pilotEvents])

    useEffect(() => {
        console.log('mounted pilot', pilot, object)
    }, [])

    useEffect(() => {
        setLoading(true)
        if (object.objectId) {
            setPilot(object.pilot)
            setNoAgenda(object.noAgenda)
            setconstraintMin(constraints?.[object.objectId]?.constraintMin || 19)
            setconstraintMax(constraints?.[object.objectId]?.constraintMax || 26)
            setconstraintMinHeat(constraints?.[object.objectId]?.constraintMinHeat || 19)
            setconstraintMaxHeat(constraints?.[object.objectId]?.constraintMaxHeat || 26)
            let events = constraints?.[object.objectId]?.events?.map((el) => {
                return {
                    ...el,
                    start: moment(`${el.startDate} ${el.startTime}`, 'YYYY-MM-DD HH:mm').toDate(),
                    end: moment(`${el.endDate} ${el.endTime}`, 'YYYY-MM-DD HH:mm').toDate(),
                }
            })
            setEvents(events || [])
            setPilotEvents(constraints?.[object.objectId]?.pilotEvents || [])
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object])

    const isValid = (e) => {
        let valid = true

        for (let i = 0; i < events.length; i++) {
            let start = events[i].start
            let end = events[i].end
            if (
                (e.start > start && e.start < end && e.event?.id !== events[i].id) ||
                (e.end > start && e.end < end && e.event?.id !== events[i].id)
            )
                valid = false
        }
        return valid
    }

    const snackApply = () => {
        let _events = []
        let day = moment(snackData?.start).date()
        for (let i = 1; i <= 7; i++) {
            if (i == day) continue
            let start = moment(snackData?.start).date(i).toDate()

            let end = moment(snackData?.end).date(i).toDate()
            let e = {
                ...snackData,
                start,
                end,
                slots: [],
                title: '',
                id: Math.floor(Math.random() * 10000000),
            }
            let isSlotValid = isValid(e)
            if (isSlotValid) {
                _events.push({ ...e })
            }
        }
        setEvents((events) => {
            return [...events, ..._events]
        })
        setSnack(false)
    }

    const handleMaxHeatChange = (value) => {
        if (value > 24) {
            setAlert(
                'Vous allez enregistrer une borne maximale supérieure à 24°C. Passer au dessus de cette borne impactera fortement votre consommation énergétique, les économies générées et l’impact sur l’environnement.'
            )
        } else {
            setAlert(null)
        }
        setconstraintMaxHeat(value)
        setModified(true)
    }

    const handleMinChange = (value) => {
        if (value < 20) {
            setAlert(
                'Vous allez enregistrer une borne minimale inférieure à 20°C. Descendre en dessous de cette borne impactera fortement votre consommation énergétique, les économies générées et l’impact sur l’environnement.'
            )
        } else {
            setAlert(null)
        }
        setconstraintMin(value)
        setModified(true)
    }

    return (
        <>
            <Card>
                <CardContent>
                    {loading ? <div>Chargement...</div> : null}
                    <Grid
                        container
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                value={objectNewName || object.name}
                                variant="standard"
                                fullWidth
                                onChange={(e) => {
                                    setObjectNewName(e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EditIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {/* <PageTitleComponent title={selectedObject?.Name?.toUpperCase()} /> */}
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <Box
                                    style={{
                                        boxSizing: 'border-box',
                                        background: 'white',
                                        borderRadius: 3,
                                        display: 'flex',
                                        alignItems: 'center',
                                        maxHeight: 40,
                                        border: '1px solid #e0e0e0',
                                    }}
                                    sx={{ padding: { xs: '5px', sm: '5px', md: '5px 15px' } }}
                                >
                                    <Typography
                                        sx={{ fontSize: 12, marginRight: 2 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        Pilotage
                                    </Typography>

                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        {pilot ? (
                                            <img src={PilotOnSvg} style={{ marginRight: 10 }} />
                                        ) : (
                                            <img src={PilotOffSvg} style={{ marginRight: 10 }} />
                                        )}
                                    </Box>

                                    <AgridToggle checked={pilot} position="left" onChange={() => setPilot(!pilot)} />
                                </Box>

                                {SaveMenu}
                            </Box>
                        </Grid>

                        {/* <Button onClick={submit}>Sauvegarder</Button> */}
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            {alert ? (
                                <Alert severity="warning" style={{ fontSize: 12, marginTop: 10 }}>
                                    {alert}
                                </Alert>
                            ) : null}
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TemperatureInput
                                label={'Min'}
                                mode={'min'}
                                type={'cool'}
                                min={16}
                                max={constraintMax}
                                value={constraintMin}
                                onChange={(value) => handleMinChange(value)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TemperatureInput
                                type={'cool'}
                                label={'Max'}
                                min={constraintMin}
                                mode={'max'}
                                max={30}
                                value={constraintMax}
                                onChange={(value) => {
                                    setconstraintMax(value)
                                    setModified(true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TemperatureInput
                                label={'Min'}
                                mode={'min'}
                                type={'heat'}
                                min={16}
                                max={constraintMaxHeat}
                                value={constraintMinHeat}
                                onChange={(value) => {
                                    setconstraintMinHeat(value)
                                    setModified(true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TemperatureInput
                                label={'Max'}
                                mode={'max'}
                                type={'heat'}
                                min={constraintMinHeat}
                                max={30}
                                value={constraintMaxHeat}
                                onChange={(value) => handleMaxHeatChange(value)}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'block',
                            },
                        }}
                    >
                        {process.env.NODE_ENV === 'development' ? (
                            <Select
                                value={agendaType}
                                onChange={(e) => setAgendaType(e.target.value)}
                                style={{ marginTop: 20 }}
                            >
                                <MenuItem value={'classic'}>Agenda de la zone</MenuItem>
                                <MenuItem value={'pilot'}>Agenda Pilotage</MenuItem>
                            </Select>
                        ) : null}

                        {agendaType === 'classic' ? (
                            <Box
                                style={{
                                    boxSizing: 'border-box',
                                    background: 'white',
                                    borderRadius: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxHeight: 40,
                                    marginTop: 20,
                                }}
                            >
                                <Typography sx={{ fontSize: 12, marginRight: 2 }} color="text.secondary" gutterBottom>
                                    Agenda autorisé
                                </Typography>

                                <AgridToggle
                                    checked={!noAgenda}
                                    position="left"
                                    onChange={() => setNoAgenda(!noAgenda)}
                                    label={''}
                                />
                            </Box>
                        ) : null}
                        {!noAgenda && agendaType === 'classic' && (
                            <CalendarLinearComponent events={events} setEvents={setEvents} />
                        )}
                        {agendaType === 'pilot' && (
                            <Alert severity="info" style={{ marginTop: 10 }}>
                                {' '}
                                Sélectionnez les plages horaires où vous souhaitez que le pilotage ne soit pas actif.
                            </Alert>
                        )}
                        {agendaType === 'pilot' && (
                            <CalendarLinearComponent
                                events={pilotEvents}
                                setEvents={setPilotEvents}
                                color={'#f44336'}
                            />
                        )}
                    </Box>
                    {/* {selectedSite?.AgridLite ? (
                        <AgridLite selectedObject={selectedObject} selectedSite={selectedSite} />
                    ) : null} */}
                </CardContent>
            </Card>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => {
                    setSnack(false)
                }}
                message="Appliquer à tous les jours"
                action={
                    <React.Fragment>
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => {
                                snackApply()
                            }}
                        >
                            OK
                        </Button>
                    </React.Fragment>
                }
            />
        </>
    )
}

export default ConstraintModal
