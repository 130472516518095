import AirCon from '@/assets/img/realtime/air-conditioner.svg'
import Thermometer from '@/assets/img/realtime/thermometer.svg'
import { DataType, IndicatorDataType, SpaceType, Mode } from './types'
import Remote from '@/assets/img/realtime/remote.svg'

import Restaurant from '@/assets/img/realtime/restaurant.webp'
import Office from '@/assets/img/realtime/office.webp'
import OpenSpace from '@/assets/img/realtime/open_space.webp'
import Other from '@/assets/img/realtime/other.webp'
import TechnicalZone from '@/assets/img/realtime/Technical_Zone.webp'
import Room from '@/assets/img/realtime/bedroom.webp'
import CommonSpace from '@/assets/img/realtime/Common_Space.webp'

import CoolModeSvg from '@/assets/img/realtime/cool-mode.svg'
import DryModeSvg from '@/assets/img/realtime/dry-mode.svg'
import FanModeSvg from '@/assets/img/realtime/fan-mode.svg'
import HeatModeSvg from '@/assets/img/realtime/heat-mode.svg'

export const DATA_TYPE_ICONS_SRC: Record<IndicatorDataType, string> = {
    [DataType.STATE]: Remote,
    [DataType.TEMPERATURE]: Thermometer,
    [DataType.SETPOINT_TEMPERATURE]: AirCon,
}

export const SPACE_TYPE_IMG_SRC: Record<SpaceType, string> = {
    [SpaceType.HOTEL_ROOM]: Room,
    [SpaceType.COMMON_AREA]: CommonSpace,
    [SpaceType.RESTAURANT]: Restaurant,
    [SpaceType.TECHNICAL_ZONE]: TechnicalZone,
    [SpaceType.OFFICE]: Office,
    [SpaceType.MEETING_ROOM]: TechnicalZone,
    [SpaceType.OPEN_SPACE]: OpenSpace,
    [SpaceType.OTHER]: Other,
}

export const MODE_ICONS: Record<Mode, string | undefined> = {
    [Mode.HEAT]: HeatModeSvg,
    [Mode.COOL]: CoolModeSvg,
    [Mode.AUTO]: CoolModeSvg,
    [Mode.FAN]: FanModeSvg,
    [Mode.UNKNOWN]: undefined,
}
