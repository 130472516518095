import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { linkBlue } from '../../components/Basic/colors'
import { help } from '../../services/user.service'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Support from '../../assets/img/help/support.png'

export const ContactForm = () => {
    const me = useSelector((state) => state.meStore?.me)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [selectedSite, setSelectedSite] = useState(null)

    const handleSubmit = async () => {
        setError('')
        setLoading(true)
        if (!title || !message) {
            setError('Veuillez remplir tous les champs')
        } else if (title?.length < 3 || message?.length < 3) {
            setError('Le sujet et le message doivent faire au moins 3 caractères')
        } else {
            await help({
                title: `[${selectedSite?.Name}] ${title}`,
                message,
                email: me?.Email,
                hash: selectedSite?.Hash,
            })
            setSuccess(true)
            setTitle('')
            setMessage('')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (me?.sites?.length > 0) {
            setSelectedSite(me?.sites[0])
        }
    }, [me])
    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                {' '}
                <h2>Vous avez besoin d'aide ?</h2>
            </Grid>

            <Grid item md={7}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <TextField
                            label="Sujet *"
                            placeholder="Ex: Problème de chauffage"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            style={{ background: 'white' }}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                id="input-with-icon-adornment"
                                value={me?.Email}
                                fullWidth
                                disabled={true}
                                style={{ background: '#eeeeee' }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                id="selected-site"
                                value={selectedSite?.Hash || ''}
                                onChange={(e) => {
                                    let find = me?.sites?.find((el) => el?.Hash === e.target.value)
                                    setSelectedSite(find)
                                }}
                                fullWidth
                                style={{ background: 'white' }}
                            >
                                {me?.sites?.map((el) => {
                                    return <MenuItem value={el?.Hash}>{el?.Name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        {success && (
                            <Alert severity="success">
                                C'est noté! Nous vous répondrons dans les plus brefs délais 😀
                            </Alert>
                        )}
                        {error && <Alert severity="error">{error}</Alert>}
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            label="Message *"
                            placeholder="Merci de décrire votre problème le plus précisément possible."
                            multiline
                            rows={6}
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            style={{ background: 'white' }}
                        />
                    </Grid>
                    <Grid item md={0} xs={12} />
                    <Grid item md={3} xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button variant="contained" fullWidth onClick={handleSubmit}>
                                Envoyer
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={5}>
                <img src={Support} alt="support" style={{ width: '100%', height: 'auto' }} />
            </Grid>
        </Grid>
    )
}
